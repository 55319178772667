import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Amplify, Auth, API} from "aws-amplify";
import {
    notify
} from "../graphql/mutations";

const getAmplifyConfig = async () => {
    let domain = "picster.ai";
    // ToDo: Vladimir, I can't use window.location.hostname
    // let domain = window.location.hostname;
    // if (window.location.hostname === "localhost") {
    //     domain = "fanster.ai";
    // }
    return (await fetch(`https://${domain}/amplify-config.json`)).json();
}

Amplify.configure(await getAmplifyConfig());
const signIn = () => {
    return Auth.signIn(prompt("What's your email?") ?? "", "")
        .then(user => {
            if (user.challengeName === 'CUSTOM_CHALLENGE') {
                Auth.sendCustomChallengeAnswer(user, prompt("What's the code you received in the email?") ?? "")
                    .then(user => console.log(user))
                    .catch(err => console.log(err));
            } else {
                console.log(user);
            }
        })
        .catch(err => console.log(err));
}

const signUp = () => {
    try {
        return Auth.signUp({
            username: (prompt("What's your email?") ?? ""),
            password: "oQf01Ft(&38A"
        }).then(user => {
            console.log(JSON.stringify(user));
        });
    } catch (error) {
        console.log('error signing up:', error);
    }
}

const signOut = () => {
    try {
        return Auth.signOut();
    } catch (error) {
        console.log('error signing up:', error);
    }
}

const currentSession = () => {
    return Auth.currentSession()
        .then(session => console.log(session))
        .catch(err => console.log(err));
}

const currentUser = () => {
    return Auth.currentUserInfo()
        .then(user => console.log(user))
        .catch(err => console.log(err));
}

const refreshToken = () => {
    return Auth.currentAuthenticatedUser({
        bypassCache: true
    })
        .then(user => console.log(user))
        .catch(err => console.log(err));
}

const updateAuthenticatedUserAttribute = () => {
    return Auth.currentAuthenticatedUser({
        bypassCache: false
    }).then(user => {
        const attribute = {};
        attribute[prompt("What's the name of the attribute?") ?? ""] = prompt("What's the value of the attribute?") ?? "";
        return Auth.updateUserAttributes(user, attribute)
    });
}

const verifyAuthenticatedUserAttribute = () => {
    return Auth.currentAuthenticatedUser({
        bypassCache: false
    }).then(user => {
        return Auth.verifyUserAttributeSubmit(user, prompt("What's the name of the attribute?") ?? "", prompt("What's the verification code?") ?? "")
    });
}


const apiMutationNotify = async () => {
    try {
        const graphqlApiResponse = API.graphql({
            query: notify,
            authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
            variables: {
                message: prompt("What's the message you wanna send? (respect '^[\\w\\/:;,\\.-]+$'")
            }
        });
        if (graphqlApiResponse instanceof Promise) {
            const result = await graphqlApiResponse;
            console.log(result.data);
            return graphqlApiResponse;
        }

        return graphqlApiResponse;
    } catch (e) {
        console.log(e);
    }
}

const DebugPage = () => (
    <Layout>
        <Seo title="Debug"/>
        <h1>Debug Stuff</h1>
        <div>
            <label>Web Application:</label>
            <p/>
            <button onClick={signIn}>Sign In</button>
            <button onClick={signUp}>Sign Up</button>
            <p/>
            <button onClick={signOut}>Sign Out</button>
            <button onClick={currentSession}>Current Session</button>
            <button onClick={currentUser}>Current User</button>
            <p/>
            <button onClick={refreshToken}>Refresh Token</button>
            <p/>
            <label>Cognito:</label>
            <p/>
            <button onClick={updateAuthenticatedUserAttribute}>Update Authenticated User Attribute</button>
            <button onClick={verifyAuthenticatedUserAttribute}>Verify Authenticated User Attribute</button>
            <p/>
            <label>Mutations:</label>
            <p/>
            <button onClick={apiMutationNotify}>Notify</button>
            <p/>
        </div>
    </Layout>
)

export default DebugPage
